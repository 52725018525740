<template>
    <form-view 
        v-slot="{ onSuccess, onBack }"
        title="Новая тема"
        create
        url-back="/theme"
    >
        <theme-form @success="onSuccess" @back="onBack" />
    </form-view>
</template>
<script>
import FormView from '@/components/crud/FormView.vue'
import ThemeForm from '@/components/forms/ThemeForm.vue'

export default {
    name: 'LessonPlanCreate',
    components: { FormView, ThemeForm }
}
</script>